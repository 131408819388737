import { SimplePaletteColorOptions } from '@mui/material'
import {
  Components,
  createTheme,
  PaletteOptions,
  Theme,
  TypeBackground,
} from '@mui/material/styles'
import { darkPalette, palette } from './colors'
import { typography } from './typography'
import { breakpoints, shadows } from './values'
// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line
//   interface DefaultTheme extends Theme {}
// }
import '@mui/material/styles/createPalette'

// import { nextFonts } from '@public/fonts/fonts'

interface ExtendedPaletteColorOptions extends SimplePaletteColorOptions {
  darker?: string
  wizard?: string
  lighter?: string
  lightDark?: string
  lightest?: string
  halfTransparent?: string
  button?: string
  lightOnSurface?: string
  light?: string
  background?: string
  surface?: string
  textPrimary?: string
  textSecondary?: string
  10?: string
  20?: string
  40?: string
  50?: string
  95?: string
  100?: string
  200?: string
  300?: string
  400?: string
  500?: string
  600?: string
  800?: string
  900?: string
  950?: string
}

interface ExtendedBackground extends TypeBackground {
  backdrop: string
  modalBorder: string
  secondaryBackground: string
}

// declare module '@mui/material/styles/createTheme' {
//   interface Theme {
//     containerMaxWidth?: number | string
//     sidebar?: any
//   }
// }

declare module '@mui/material/styles' {
  interface Theme {
    extras: {
      name: string
      darkMode: boolean
      overrideAll: boolean
    }
  }

  // allow configuration using `createTheme()`
  interface ThemeOptions {
    extras?: {
      name?: string
      darkMode?: boolean
      overrideAll?: boolean
    }
  }
}

// // Helper function to blend colors based on opacity
// const blendWithBackground = (
//   color: string,
//   opacity: number,
//   isLight: boolean,
// ) => {
//   const backgroundColor = isLight ? '#FFFFFF' : '#000000'
//   // For light mode, lighten the color; for dark mode, darken it
//   return isLight
//     ? lighten(color, 1 - opacity / 100)
//     : darken(color, 1 - opacity / 100)
// }

export const getComponents = (
  palette: PaletteOptions,
): Components<Omit<Theme, 'components'>> => ({
  MuiPaper: {
    styleOverrides: {
      root: {
        borderRadius: 8,
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      list: {
        padding: 0,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: 16,
        '&:not(:last-child)': {
          borderBottom: `1px solid ${palette.divider}`,
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      sizeLarge: {
        height: 48,
      },
    },
    defaultProps: {
      color: 'inherit',
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: palette.divider,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&.MuiInputBase-root': {
          backgroundColor: palette.background?.input,
        },
      },
    },
  },
})

export const shape = {
  borderRadius: 8,
}

export const lightTheme = createTheme({
  extras: {
    name: 'light',
    darkMode: false,
  },
  shape,
  palette: palette,
  typography: typography,
  components: getComponents(palette),
  breakpoints: breakpoints,
  shadows: shadows,
})

export const darkTheme = createTheme({
  extras: {
    name: 'dark',
    darkMode: true,
  },
  shape,
  palette: darkPalette,
  typography: typography,
  components: {
    ...getComponents(darkPalette),
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: darkPalette.text.primary,
          },
        },
      },
    },
  },
  breakpoints: breakpoints,
  shadows: shadows,
})

// // Add this interface to define the theme structure
// interface EventTheme {
//   colourScheme: {
//     name: string
//     // Light mode colors
//     colorBackground: string
//     colorTextPrimary: string
//     colorTextSecondary: string
//     colorButtonPrimary: string
//     colorButtonSecondary: string
//     // Dark mode colors
//     colorDarkBackground: string
//     colorDarkTextPrimary: string
//     colorDarkTextSecondary: string
//     colorDarkButtonPrimary: string
//     colorDarkButtonSecondary: string
//   }
//   typography: {
//     className: string
//     fontDisplay: string
//     fontBody: string
//     name: string
//     fontDisplaySize: number
//   }
//   darkMode: boolean
// }

// export const createCustomTheme = (
//   eventTheme: EventTheme,
//   overrideAll = false,
// ) => {
//   // Get the Next.js font instances for both display and body fonts
//   const nextDisplayFont = nextFonts[eventTheme.typography?.fontDisplay]
//   const nextBodyFont = nextFonts[eventTheme.typography?.fontBody]
//
//   const customPalette = {
//     ...(eventTheme.darkMode ? darkPalette : palette),
//     ...(eventTheme.colourScheme && {
//       background: {
//         ...(eventTheme.darkMode ? darkPalette.background : palette.background),
//         default: eventTheme.darkMode
//           ? eventTheme.colourScheme.colorDarkBackground
//           : eventTheme.colourScheme.colorBackground,
//         paper: eventTheme.darkMode ? '#111111' : 'white',
//         secondary: eventTheme.darkMode
//           ? lighten(eventTheme.colourScheme.colorDarkBackground, 0.1)
//           : 'white',
//         backdrop: eventTheme.darkMode
//           ? 'rgba(0, 0, 0, 0.5)'
//           : 'rgba(250, 250, 250, 0.5)',
//         input: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkBackground
//             : eventTheme.colourScheme.colorButtonPrimary,
//           eventTheme.darkMode ? 95 : 5,
//           true,
//         ),
//       },
//       text: {
//         primary: eventTheme.darkMode
//           ? eventTheme.colourScheme.colorDarkTextPrimary
//           : eventTheme.colourScheme.colorTextPrimary,
//         secondary: eventTheme.darkMode
//           ? eventTheme.colourScheme.colorDarkTextSecondary
//           : eventTheme.colourScheme.colorTextSecondary,
//         tertiary: eventTheme.darkMode
//           ? lighten(eventTheme.colourScheme.colorDarkTextSecondary, 0.3)
//           : lighten(eventTheme.colourScheme.colorTextSecondary, 0.3),
//       },
//       primary: {
//         ...(eventTheme.darkMode ? darkPalette.primary : palette.primary),
//         main: eventTheme.darkMode
//           ? eventTheme.colourScheme.colorDarkButtonPrimary
//           : eventTheme.colourScheme.colorButtonPrimary,
//         light: eventTheme.darkMode
//           ? lighten(eventTheme.colourScheme.colorDarkButtonPrimary, 0.2)
//           : lighten(eventTheme.colourScheme.colorButtonPrimary, 0.9),
//         dark: eventTheme.darkMode
//           ? darken(eventTheme.colourScheme.colorDarkButtonPrimary, 0.2)
//           : darken(eventTheme.colourScheme.colorButtonPrimary, 0.2),
//         100: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkButtonPrimary
//             : eventTheme.colourScheme.colorButtonPrimary,
//           60,
//           !eventTheme.darkMode,
//         ),
//       },
//       secondary: {
//         ...(eventTheme.darkMode ? darkPalette.secondary : palette.secondary),
//         main: eventTheme.darkMode
//           ? eventTheme.colourScheme.colorDarkButtonSecondary
//           : eventTheme.colourScheme.colorButtonSecondary,
//         light: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkButtonSecondary
//             : eventTheme.colourScheme.colorButtonSecondary,
//           60,
//           !eventTheme.darkMode,
//         ),
//         100: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkButtonSecondary
//             : eventTheme.colourScheme.colorButtonSecondary,
//           60,
//           !eventTheme.darkMode,
//         ),
//         200: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkButtonSecondary
//             : eventTheme.colourScheme.colorButtonSecondary,
//           80,
//           !eventTheme.darkMode,
//         ),
//         800: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkButtonSecondary
//             : eventTheme.colourScheme.colorButtonSecondary,
//           100,
//           !eventTheme.darkMode,
//         ),
//       },
//       red: {
//         ...(eventTheme.darkMode ? darkPalette.red : palette.red),
//         main: eventTheme.darkMode
//           ? eventTheme.colourScheme.colorDarkTextSecondary
//           : eventTheme.colourScheme.colorTextSecondary,
//         light: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkTextSecondary
//             : eventTheme.colourScheme.colorTextSecondary,
//           60,
//           !eventTheme.darkMode,
//         ),
//         100: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkTextSecondary
//             : eventTheme.colourScheme.colorTextSecondary,
//           60,
//           !eventTheme.darkMode,
//         ),
//         200: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkTextSecondary
//             : eventTheme.colourScheme.colorTextSecondary,
//           80,
//           !eventTheme.darkMode,
//         ),
//         800: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkTextSecondary
//             : eventTheme.colourScheme.colorTextSecondary,
//           100,
//           !eventTheme.darkMode,
//         ),
//       },
//       grey: {
//         ...palette.grey,
//         100: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkBackground
//             : eventTheme.colourScheme.colorButtonPrimary,
//           eventTheme.darkMode ? 95 : 5,
//           true,
//         ),
//         150: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkBackground
//             : eventTheme.colourScheme.colorButtonPrimary,
//           eventTheme.darkMode ? 90 : 10,
//           true,
//         ),
//         200: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkBackground
//             : eventTheme.colourScheme.colorButtonPrimary,
//           eventTheme.darkMode ? 85 : 15,
//           true,
//         ),
//         300: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkBackground
//             : eventTheme.colourScheme.colorButtonPrimary,
//           eventTheme.darkMode ? 80 : 20,
//           true,
//         ),
//         400: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkBackground
//             : eventTheme.colourScheme.colorButtonPrimary,
//           eventTheme.darkMode ? 70 : 30,
//           true,
//         ),
//         500: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkBackground
//             : eventTheme.colourScheme.colorButtonPrimary,
//           eventTheme.darkMode ? 60 : 40,
//           true,
//         ),
//         600: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkBackground
//             : eventTheme.colourScheme.colorButtonPrimary,
//           eventTheme.darkMode ? 55 : 45,
//           true,
//         ),
//         700: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkBackground
//             : eventTheme.colourScheme.colorButtonPrimary,
//           eventTheme.darkMode ? 50 : 50,
//           true,
//         ),
//         800: blendWithBackground(
//           eventTheme.darkMode
//             ? eventTheme.colourScheme.colorDarkBackground
//             : eventTheme.colourScheme.colorButtonPrimary,
//           eventTheme.darkMode ? 40 : 60,
//           true,
//         ),
//       },
//       divider: eventTheme.darkMode
//         ? lighten(eventTheme.colourScheme.colorDarkBackground, 0.25)
//         : darken(eventTheme.colourScheme.colorBackground, 0.05),
//     }),
//   }
//
//   return createTheme({
//     extras: {
//       name: eventTheme.colourScheme
//         ? 'custom'
//         : eventTheme.darkMode
//           ? 'dark'
//           : 'light',
//       darkMode: eventTheme.darkMode,
//       overrideAll,
//     },
//     shape,
//     palette: customPalette,
//     typography: {
//       ...typography,
//       ...(eventTheme.typography && {
//         fontFamily: nextBodyFont
//           ? nextBodyFont.style.fontFamily
//           : typography.fontFamily,
//         h3: {
//           fontFamily: nextDisplayFont
//             ? nextDisplayFont.style.fontFamily
//             : typography.fontFamily,
//           fontSize: eventTheme.typography.fontDisplaySize ?? 32,
//         },
//       }),
//     },
//     components: getComponents(customPalette),
//     breakpoints,
//     shadows,
//   })
// }
