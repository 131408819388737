import React, { useEffect, useRef } from 'react'
import { AppCacheProvider } from '@mui/material-nextjs/v15-pagesRouter'
// import theme from 'shared-components/theme'
import 'config/i18n'
import '../public/index.css'
// import StylesProvider from '@mui/styles/StylesProvider'
import CssBaseline from '@mui/material/CssBaseline'
// import createGenerateClassName from '@mui/styles/createGenerateClassName'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useTranslation } from 'react-i18next'
import NextNProgress from 'nextjs-progressbar'
import { useRouter } from 'next/router'
import apiClient from '../src/shared-components/utils/ApiClient'
import { ThemeProvider } from '@mui/material/styles'
// import { ThemeProvider } from '@mui/styles'
import mixpanel from 'mixpanel-browser'
import CookieModal from '../src/components/Cookies/CookieModal.js'
import WebSessionTracking from 'shared-components/utils/MixpanelWebSession'
import { PreviousRouteContext } from '../src/context/PreviousRouteContext'
import { usePathname } from 'next/navigation.js'
import { darkTheme, lightTheme } from '../src/shared-components/theme'
import { AppProps } from 'next/app'
import { wrapper } from '@redux/store'

const AppWrapper = dynamic(() => import('../src/components/AppWrapper'))
// const mixpanel = dynamic(() => import('mixpanel-browser'))

// const generateClassName = createGenerateClassName({
//   productionPrefix: 'c_app',
//   seed: 'c_app',
//   // productionPrefix: '',
//   // seed: '',
// })

export const usePreviousRoute = () => {
  const pathname = usePathname()
  const previousPathRef = useRef(pathname)
  const ref = useRef(
    typeof window !== 'undefined' ? document.referrer : pathname,
  )

  useEffect(() => {
    if (previousPathRef.current !== pathname) {
      ref.current = previousPathRef.current
      previousPathRef.current = pathname
    }
  }, [pathname])

  return ref.current
}

function MyApp(props: AppProps) {
  const { Component, pageProps } = props
  const { t, ready } = useTranslation('common')
  const router = useRouter()
  const query = router.query
  const utmSource = query.utm_source
  const utmMedium = query.utm_medium
  const utmCampaign = query.utm_campaign
  const previousRoute = usePreviousRoute()

  const themeQuery = router.query.theme
  const themeToUse = themeQuery === 'dark' ? darkTheme : lightTheme

  useEffect(() => {
    if (!!utmSource || !!utmMedium || !!utmCampaign) {
      mixpanel.track('Open UTM Link', {
        'UTM Source': utmSource,
        'UTM Medium': utmMedium,
        'UTM Campaign': utmCampaign,
      })
      apiClient.general.linkOpened(utmSource, utmMedium, utmCampaign)
    }
  }, [utmSource, utmMedium, utmCampaign])

  // useEffect(() => {
  //   if (!featured.loading && featured.featuredPages.length === 0) {
  //     dispatch(fetchFeaturedPages())
  //   }
  // }, [featured])

  // useEffect(() => {
  //   ampli.load({
  //     client: {
  //       apiKey: process.env.NEXT_PUBLIC_AMPLITUDE_KEY,
  //       configuration: {
  //         minIdLength: 1 // Set minimum ID length to 1 instead of the default of 5 to allow user IDs below 10000 to be tracked
  //       }
  //     }
  //   })
  // }, [])

  if (!ready) {
    return null
  }

  if (Component.trimApp) {
    return (
      <>
        <AppCacheProvider {...props}>
          <Head>
            <title>{t('websiteTitle')}</title>
            <meta
              name='description'
              content='Where Hype Starts'
              key='description'
            />
            <meta
              name='viewport'
              content='minimum-scale=1, initial-scale=1, width=device-width'
            />
            <meta key='ogTitle' property='og:title' content='BASH' />
            <meta
              key='ogDescription'
              property='og:description'
              content='Where Hype Starts'
            />
            <meta
              key='ogImage'
              property='og:image'
              itemProp='image'
              content='/android-chrome-192x192.png'
            />
          </Head>
          <ThemeProvider theme={themeToUse}>
            <CssBaseline />
            <Component {...pageProps} />
          </ThemeProvider>
        </AppCacheProvider>
      </>
    )
  }

  return (
    <>
      <AppCacheProvider {...props}>
        <PreviousRouteContext.Provider value={previousRoute}>
          <AppWrapper
            appProps={props}
            title={
              Component.disableTitle
                ? null
                : (Component.title ?? t('websiteTitle'))
            }
          >
            {!Component.hideProgress && (
              <NextNProgress
                color='#A373F1'
                startPosition={0.6}
                stopDelayMs={100}
                height={2}
                options={{
                  showSpinner: false,
                }}
                showOnShallow={false}
              />
            )}
            {!router.pathname.endsWith('/widget') &&
              !router.pathname.endsWith('/widget-new') && (
                <CookieModal></CookieModal>
              )}
            <WebSessionTracking />
            <Component {...pageProps} />
            {/* {!isDesktop && isLoggedIn && <NavBar />} */}
          </AppWrapper>
        </PreviousRouteContext.Provider>
      </AppCacheProvider>
    </>
  )
}

// export default MyApp

export default wrapper.withRedux(MyApp)
