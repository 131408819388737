// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"x1zsyb0H2WfAf8txzGBKr"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const environment = process.env.NEXT_PUBLIC_ENVIRONMENT_STAGE

if (environment !== 'local') {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://71fc67b19e0147b9bd35e4d9e31929f5@o525622.ingest.sentry.io/5991348',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.0,
    debug: false,
    environment: environment,
    ignoreErrors: [
      '403',
      '404',
      /Network Error/i,
      /Fetch Error/i,
      /Failed to fetch/i,
      /Load failed/i,
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
